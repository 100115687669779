<template>
  <v-app-bar
    id="app-bar"
    flat
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important"
    color="white"
    dense
    app
  >
  <!-- color="transparent" -->
    <v-btn
      class="mr-3"
      v-if="!$vuetify.breakpoint.mdAndUp"
      elevation="1"
      fab
      x-small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="font-weight-light d-flex align-center"
    >
    <!-- <v-toolbar-title class="font-weight-light" v-else>  -->
      <!-- hidden-sm-and-down -->
      <a @click="$router.go(-1)" v-if="$route.meta.sub_page" class="black--text text-decoration-underline">
      {{ $route.meta.parent }}/</a>
      <template v-if="$route.name !== 'Fulfill'">
        <template v-if="!editing || !$route.params.shelve_index">
          {{ `${$route.name} ${ ($route.params.shelve_index && shelves[+$route.params.shelve_index - 1].name) || $route.params.shelve_index || ''}` }}
          <v-btn
            small
            icon
            class="ml-2"
            @click="set_editing"
            v-if="$route.params.shelve_index !== undefined">
              <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-text-field
            dense
            x-small
            ref="shelve_name"
            class="mt-2 mx-2"
            prefix="Shelve "
            style="max-width: 7em"
            v-model="shelve_name"
            @keyup="on_update_shelve_name"
            :rules="[
              v => !!v || 'Required',
              v => !shelves.some((s, i) => s.name === v || (!s.name && i + 1 === +v)) || 'Name exists'
              ]"
          />
          <!-- v => v.length < 5 || 'Max 4 characters', -->
          <!-- :value="($route.params.shelve_index && shelves[+$route.params.shelve_index - 1].name) || $route.params.shelve_index" -->
          <!-- <v-btn
            color="primary"
            x-small
            class="ml-2"
            @click="save_shelve_name"
            >Save</v-btn>
          <v-btn
            outlined
            x-small
            class="ml-2"
            @click="editing = false"
            >Cancel</v-btn> -->
            

        </template>
      </template>
      <v-btn 
        v-if="$route.name === 'Fulfill'"
        outlined
        x-small
        class="ml-2"
        @click="refresh_bucket"
        >
        Restart
        <!-- <v-icon small>mdi-refresh</v-icon> -->
      </v-btn>
      <v-btn
        v-if="is_dev"
        outlined
        x-small
        class="ml-2"
        @click="developer"
        >Dev btn</v-btn>

    </v-toolbar-title>
    <v-spacer />
    <div
      v-if="loading_products"
      class="d-flex justify-content-space-between flex-columns"
      >
      <v-progress-circular
        size="20"
        indeterminate
        color="secondary"
        class="mr-2"
        />
        <div class="text-body-2">Syncing products</div>
    </div>
    <v-select
      v-if="user.userProfile && user.userProfile.companies.length > 1"
      :items="user.userProfile.companies"
      item-text="name"
      item-value="id"
      dense
      v-model="$store.state.warehouse.current_company_id"
      @change="change_company"
      class="mt-3"
      style="max-width: 165px"
      >
    </v-select>
    <v-chip
      color="primary"
      v-if="printserver_connected"
      @click="$router.push('/auth/settings/printers')"
      >
      <span v-if="$vuetify.breakpoint.mdAndUp">Printserver Connected</span>
      <v-icon v-else>mdi-printer-check</v-icon>
    </v-chip>
    <v-chip
      v-else-if="printserver_id"
      @click="$router.push('/auth/settings/printers')"
      color="warning"
      >
      <span v-if="$vuetify.breakpoint.mdAndUp">Printserver disconnected</span>
      <v-icon v-else>mdi-printer-alert</v-icon>
    </v-chip>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      v-if="is_fh && !($vuetify.breakpoint.mdAndDown && $route.name === 'Fulfill')"
     > 
     <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-list
      :tile="false"
      nav
      > 
      <div>
        <v-list-item
          v-for="(n, i) in user_menu"
          :key="`item-${i}`"
          @click="n.action"
        >
          <v-list-item-title />
           {{  n.title }}
        </v-list-item>
      </div>
    </v-list>
    </v-menu>
    <div style="position:absolute; bottom: -3; right: 0; width: 100%;" v-if="$route.name === 'Fulfill' && total_remaining_picks">
      <v-scale-transition>
        <v-avatar
          style="position:absolute; top: -8px; z-index: 1"
          :style="{'right': `${100 - remaining_percentage}%`}"
          size="20"
          color="primary"
          v-if="show_badge"
        >
          <span class="white--text text-caption">{{ total_remaining_picks }}</span>
        </v-avatar>
      </v-scale-transition>
      <v-progress-linear 
        :value="remaining_percentage"
        >
      </v-progress-linear>
      <!-- <v-progress-linear 
        :value="remaining_percentage"
        color="secondary"
        >
      </v-progress-linear> -->
    </div>
  </v-app-bar>
</template>

<script>
  
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: (e) => ({
      editing: false,
      original_shelve_name: '',
      shelve_name: '',
      user_menu: [
        {
          title: 'Sign out',
          action: () => { e.onSignOut() }
        }
      ],
      is_fh: process.env.VUE_APP_MODE === 'fh',
      show_badge: false
    }),

    computed: {
      ...mapState({
        drawer: state => state.app.drawer,
        shelves: state => state.warehouse.shelves,
        user: state => state.user,
        shelf_changed: state => state.warehouse.shelf_changed,
        loading_products: state => state.product.loading_products,
        printserver_connected: state => state.warehouse.printserver_connected,
        printserver_id: state => state.warehouse.company.printserver_id,
        total_remaining_picks: state => state.order.total_remaining_picks,
        starting_amount_picks: state => state.order.starting_amount_picks,
        route: state => state.route.path,
      }),
      remaining_percentage(){
        return (this.total_remaining_picks / this.starting_amount_picks) * 100
      },
      is_dev(){
        return process.env.NODE_ENV === 'development'
      }
    },
    watch: {
      remaining_percentage: function(val) {
        if(!val) return
        this.show_badge = true
        setTimeout(() => this.show_badge = false, 3000)
      },
      shelf_changed: function(val) {
        if(!val) return this.editing = false
      },
      route: function() {
        this.editing = false
      },
    },

    methods: {
      developer(){
        this.$store.dispatch('warehouse/developer')
      },
      on_update_shelve_name(){
        if(!this.$refs.shelve_name.valid) return this.shelves[+this.$route.params.shelve_index - 1].name = this.original_shelve_name
        else {
          this.$store.state.warehouse.shelves = this.shelves.map((shelve, i) => ({
            ...shelve,
            name: shelve.name || (i + 1).toString()
          }))
          this.shelves[+this.$route.params.shelve_index - 1].name = this.shelve_name
        }
        // this.shelves[+this.$route.params.shelve_index - 1].name = this.$refs.shelve_name.lazyValue
      },
      set_editing(){
        this.original_shelve_name = (this.$route.params.shelve_index && this.shelves[+this.$route.params.shelve_index - 1].name) || this.$route.params.shelve_index
        this.shelve_name = (this.$route.params.shelve_index && this.shelves[+this.$route.params.shelve_index - 1].name) || this.$route.params.shelve_index
        this.editing = true
        this.$store.commit('warehouse/CHANGE_SHELF', 0)
      },
      save_shelve_name(){
        if(!this.$refs.shelve_name.valid) return
        this.shelves[+this.$route.params.shelve_index - 1].name = this.$refs.shelve_name.lazyValue
        this.$store.dispatch('warehouse/save_company', ['shelves'])
        this.editing = false
      },
      change_company(id){
        this.$store.dispatch('warehouse/set_company', id)
      },
      ...mapMutations({
        setDrawer: 'app/SET_DRAWER',
      }),
      refresh_bucket(){
        this.$store.dispatch('order/refresh_order_bucket')
      },
    onSignOut(){
         this.$auth.logout()
            .then(() => this.$router.push('/signin'))
            .catch(e => console.log('error', e))
     }
    },
  }
</script>
